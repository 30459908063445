.load-more-item
	@apply hidden

.career-2-item
	@apply relative
	.image
		@apply aspect-[440/246] rounded-3 bg-black xl:rounded-6
		img
			@apply opacity-70
	.caption
		@apply absolute bottom-8 left-0 w-full z-1
	.title
		@apply sub-header-24 font-bold text-white pl-6 py-3 border-l-6 border-primary-2

.career-2-list
	@apply grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-10

.career-3-table
	@apply overflow-x-auto xl:overflow-visible
	table
		@apply w-full rem:min-w-[800px] xl:min-w-full
	th
		@apply border border-neutral-50 sub-header-20 font-bold text-white bg-primary-1 uppercase p-2 xl:px-5 xl:rem:py-[10px]
		&:nth-child(1)
			@apply w-20
		&:nth-child(2)
			@apply text-left
		&:nth-child(3)
			@apply w-[21%]
		&:nth-child(4)
			@apply w-[21%]
	td
		@apply border border-neutral-50 body-16 font-normal text-neutral-950 text-center p-2 xl:px-5 xl:py-3
		@apply first:font-bold
		&:nth-child(2)
			@apply text-left
		a
			@apply text-current hover-underline hover:text-primary-1
	tbody
		tr
			@apply even:bg-neutral-50
