@charset "UTF-8";
/**
 * This injects Tailwind's base styles and any base styles registered-500 by
 * plugins.
 */
@tailwind base {}/**
 * This injects Tailwind's component classes and any component classes
 * registered-500 by plugins.
 */
@tailwind components {}/**
 * This injects Tailwind's utility classes and any utility classes registered-500
 * by plugins.
 */
@tailwind utilities {}/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants {}.edit-link i:before {
  content: '✏️'; }

@keyframes ctaPhoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-15deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(15deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-15deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(15deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

.button {
  @apply flex flex-wrap gap-5 {} }

.btn-solid {
  @apply flex-center gap-3 body-16 font-medium text-white bg-primary-1 rounded-full px-4 py-2 min-h-11 {}  @apply hover:text-white xl:px-6 {} }
  .btn-solid i {
    @apply text-xl {} }
  .btn-solid .icon {
    @apply rem:w-[18px] overflow-hidden flex items-center {} }
    .btn-solid .icon > * {
      @apply -translate-x-full w-full flex-center tsn {} }
  .btn-solid:hover .icon > * {
    @apply translate-x-0 {} }
  .btn-solid.is-red {
    @apply bg-[#E20000] hover:bg-[#C60202] {} }
  .btn-solid.is-light-green {
    @apply bg-primary-2 hover:bg-[#5C8C24] {} }

.btn-lined {
  @apply flex-center gap-3 body-16 font-medium text-primary-1 border border-current rounded-full px-4 py-2 min-h-11 {}  @apply hover:text-white hover:bg-primary-1 xl:px-6 {} }

#buttonMenu {
  @apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none m-0 p-0 bg-none {} }
  #buttonMenu .line {
    @apply absolute block h-[2px] w-full bg-primary-1 rounded-full opacity-100 left-0 rotate-0 transition-all {} }
    #buttonMenu .line:nth-child(1) {
      @apply top-[4px] {} }
    #buttonMenu .line:nth-child(2) {
      @apply top-1/2 -translate-y-1/2 {} }
    #buttonMenu .line:nth-child(3) {
      @apply bottom-[4px] {} }
  #buttonMenu #pulseMe {
    @apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1 {} }
  #buttonMenu .bar {
    @apply bg-primary-1 rounded-1 absolute shadow-bar {} }
    #buttonMenu .bar.left {
      @apply w-px animate-left-bar {} }
    #buttonMenu .bar.top {
      @apply h-px animate-top-bar {} }
    #buttonMenu .bar.right {
      @apply w-px animate-right-bar {} }
    #buttonMenu .bar.bottom {
      @apply h-px animate-bottom-bar {} }
  #buttonMenu.open .line:nth-child(2), #buttonMenu:hover .line:nth-child(2) {
    @apply w-[15px] {} }
  #buttonMenu.open .bar, #buttonMenu:hover .bar {
    @apply hidden {} }

@screen xl {
  #buttonMenu {
    display: none !important; } }

.alert {
  @apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm {} }
  .alert button {
    @apply hidden {} }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.backdrop {
  @apply fixed z-50 size-full top-0 left-0 bg-black/75 backdrop-blur hidden {} }

.cta-fixed {
  @apply fixed z-50 bottom-24 right-3 md:bottom-5 xl:bottom-15 xl:right-10 {} }
  .cta-fixed ul {
    @apply flex flex-col gap-2 xl:gap-3 {} }
  .cta-fixed li {
    animation: ctaPhoneRing 1s infinite ease-in-out;
    @apply size-12 rounded-full flex-center overflow-hidden bg-primary-2 text-white text-xl transition-all shadow-drop-shadow-light hover:bg-primary-1 xl:size-15 xl:text-2xl {} }
    .cta-fixed li a {
      @apply size-full flex-center p-2 {} }
    .cta-fixed li.back-to-top {
      @apply bg-neutral-500 opacity-0 pointer-events-none hover:bg-neutral-700 {} }
      .cta-fixed li.back-to-top.active {
        @apply opacity-100 pointer-events-auto {} }
  .cta-fixed .ActionMenu {
    left: auto !important;
    right: 0 !important; }

.social-list ul {
  @apply flex flex-wrap rem:gap-[10px] {} }

.social-list a {
  @apply size-14 rounded-1 flex-center text-white text-2xl bg-secondary-800 tsn hover:bg-secondary-950 {} }

.dropdown {
  @apply relative {} }
  .dropdown .dropdown-toggle {
    @apply text-neutral-700 flex items-center justify-between gap-4 cursor-pointer {} }
  .dropdown .dropdown-menu {
    @apply absolute top-full mt-5 left-0 w-full min-w-max z-10 bg-white rounded-1 px-5 py-3 shadow opacity-0 pointer-events-none transition-all duration-200 ease-linear {} }
  .dropdown.open .dropdown-toggle i, .dropdown.open .dropdown-toggle em {
    @apply rotate-180 {} }
  .dropdown.open .dropdown-menu {
    @apply opacity-100 pointer-events-auto {} }

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
textarea,
select {
  @apply bg-white border border-neutral-200 pl-5 pr-10 py-2 rounded-full text-base font-normal leading-normal text-neutral-500 w-full bg-no-repeat h-11 m-0 {}  @apply focus:outline-none focus:ring-2 focus:ring-secondary-700 focus:ring-offset-0 {} }

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'] {
  @apply placeholder:text-neutral-500 {}  @apply disabled:bg-neutral-500/50 disabled:border-neutral-500/75 disabled:text-neutral-900 {}  @apply read-only:bg-neutral-100 read-only:border-neutral-200 read-only:text-neutral-500 {} }

select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%23A6BD39' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 8l4 4 4-4'/></svg>");
  @apply bg-right appearance-none {} }

select[multiple] {
  @apply min-h-[calc(48/1920*100rem)] py-3 h-auto bg-none {} }

textarea {
  @apply h-20 py-3 rounded-6 xl:py-5 xl:h-30 {}  @apply placeholder:text-neutral-500 {} }

input[type='file'] {
  @apply hidden {} }
  input[type='file'] ~ label {
    @apply relative w-max bg-neutral-600 border-none h-12 px-6 py-3 text-base text-primary-1 font-normal flex-center gap-3 cursor-pointer m-0 transition-all duration-200 ease-linear hover:bg-neutral-700 {} }

input[type='checkbox'] {
  @apply hidden {} }
  input[type='checkbox'] ~ label {
    @apply relative pl-5 {} }
    input[type='checkbox'] ~ label::before {
      @apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal {} }
  input[type='checkbox']:checked ~ label::before {
    @apply content-['\f14a'] {} }

input[type='radio'] {
  @apply hidden {} }
  input[type='radio'] ~ label {
    @apply relative pl-5 {} }
    input[type='radio'] ~ label::before {
      @apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal {} }
  input[type='radio']:checked ~ label::before {
    @apply content-['\f192'] {} }

.form-group {
  @apply relative {} }
  .form-group > label {
    @apply relative block text-base font-normal leading-normal text-neutral-800 {} }
  .form-group [class*='fa-ex'] {
    @apply text-[12px] text-red-700 italic block mt-1 font-sans font-normal {} }

.frm-btnwrap {
  @apply mt-5 flex-center {} }
  .frm-btnwrap label.label, .frm-btnwrap .frm-btn-reset {
    @apply hidden {} }
  .frm-btnwrap input[type='submit'], .frm-btnwrap button[type='submit'] {
    @apply h-11 rounded-full bg-primary-2 px-4 border-none text-base font-medium text-white text-center tsn xl:px-6 {}    @apply hover:bg-primary-1 {} }

.frm-captcha {
  @apply flex flex-wrap mt-0 lg:flex-row-reverse lg:justify-end {} }
  .frm-captcha > div {
    @apply mt-5 {} }
  .frm-captcha label {
    @apply hidden {} }
  .frm-captcha .frm-captcha-input {
    @apply w-full lg:w-auto {} }
    .frm-captcha .frm-captcha-input input {
      @apply w-full xl:max-w-[calc(260/1920*100rem)] {} }
  .frm-captcha .RadCaptcha {
    @apply flex flex-col-reverse {}    @apply w-max {} }
    .frm-captcha .RadCaptcha > div {
      @apply w-full {} }
      .frm-captcha .RadCaptcha > div > div {
        @apply flex items-center {} }
    .frm-captcha .RadCaptcha > span {
      @apply text-[12px] text-secondary-700 italic block mt-1 font-sans font-normal {} }
  .frm-captcha .rcRefreshImage {
    font-size: 0;
    @apply before:font-awesome before:content-['\f2ea'] before:text-2xl before:text-blue-500 before:mx-5 xl:before:text-[calc(22/1920*100rem)] xl:before:ml-[calc(25.5/1920*100rem)] xl:before:mr-[calc(19.5/1920*100rem)] {} }

.RadUpload {
  @apply max-w-full {} }
  .RadUpload input[type="file"] {
    @apply block {} }
  .RadUpload .ruInputs .ruRemove {
    @apply text-0 w-auto {} }
  .RadUpload .ruInputs li:not(:last-child) {
    @apply absolute top-0 left-0 flex items-center m-0 z-2 w-full px-5 bg-white h-11 rounded-full border border-neutral-200 {} }
  .RadUpload .ruInputs li:last-child {
    @apply mb-0 opacity-0 {} }
    .RadUpload .ruInputs li:last-child .ruFileWrap {
      @apply block w-full h-11 rounded-full border border-neutral-200 bg-neutral-100 text-neutral-500 flex-center {} }
      .RadUpload .ruInputs li:last-child .ruFileWrap input {
        @apply w-full h-full bg-transparent border-none text-neutral-500 text-left px-5 {} }

.qupload label {
  @apply flex items-center cursor-pointer w-full h-11 rounded-full border border-neutral-200 bg-neutral-100 text-neutral-500 flex-center px-5 {} }

.qupload .RadUpload {
  @apply relative z-1 -mt-11 {} }

.apply-frm {
  @apply p-5 {} }
  .apply-frm .form-group {
    @apply mb-8 last:mb-0 {} }
    .apply-frm .form-group label {
      @apply text-base w-full text-main font-normal mb-1 {} }
      .apply-frm .form-group label span {
        @apply text-secondary-700 text-sm {} }
    .apply-frm .form-group input:not([type="button"]), .apply-frm .form-group textarea {
      @apply border-neutral-700 {} }
  .apply-frm .frm-btn {
    @apply w-full flex justify-end {} }

@screen lg {
  .apply-frm {
    @apply py-8 px-10 {} }
    .apply-frm .form-group {
      @apply flex gap-8 {} }
      .apply-frm .form-group label {
        @apply w-[30%] mb-0 text-base {} }
      .apply-frm .form-group input:not([type="button"]) {
        @apply h-10 text-base {} }
      .apply-frm .form-group textarea {
        @apply h-25 text-base {} } }

.subscribefrm .fa {
  @apply font-sans text-xs text-red-700 font-normal italic block mt-1 {} }
  .subscribefrm .fa::before {
    @apply font-awesome content-['\f071'] not-italic mr-1 {} }

.subscribefrm .subscribebutton {
  @apply h-11 bg-primary-2 rounded-full flex-center gap-3 text-center body-16 font-medium text-white px-6 mt-3 tsn hover:bg-[#5C8C24] {} }
  .subscribefrm .subscribebutton::after {
    @apply font-awesome content-['\f061'] font-light text-xl not-italic {} }

.modal {
  @apply bg-transparent bg-none p-0 w-full max-w-7xl hidden {} }
  .modal .modal-wrap {
    @apply bg-white p-5 rounded-2 shadow {} }

.modal-product {
  @apply rem:max-w-[760px] {} }
  .modal-product .modal-wrap {
    @apply xl:rounded-6 xl:py-15 xl:px-10 {} }

.modal-apply-now {
  @apply rem:max-w-[846px] {} }
  .modal-apply-now .modal-wrap {
    @apply xl:px-10 xl:py-15 xl:rounded-6 {} }
  .modal-apply-now .row {
    @apply rem:-mx-[10px] {} }
    .modal-apply-now .row > * {
      @apply rem:px-[10px] {} }

.pagination {
  @apply flex-center flex-wrap gap-3 mt-8 xl:mt-10 xl:gap-6 {} }
  .pagination span, .pagination a {
    @apply flex-center size-10 body-18 font-bold text-neutral-500 border-2 border-neutral-200 rounded-1 tsn {}    @apply hover:bg-neutral-50 {} }
  .pagination li.active span, .pagination li.active a {
    @apply bg-primary-2 text-white border-primary-2 {} }

.custom-scroll-dark::-webkit-scrollbar {
  width: 14px;
  height: 14px; }

.custom-scroll-dark::-webkit-scrollbar-button {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-track {
  background-color: #646464 !important; }

.custom-scroll-dark::-webkit-scrollbar-track-piece {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #242424 !important;
  border: 2px solid #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(130deg, #696969 40%, rgba(255, 0, 0, 0) 41%), linear-gradient(230deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(0deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(310deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(50deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(180deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(330deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(90deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(150deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(270deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.swiper-pagination .swiper-pagination-bullet {
  @apply opacity-100 w-3 h-3 rounded-full bg-secondary-700/50 {} }
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-secondary-700 {} }

.swiper-pagination.is-white .swiper-pagination-bullet {
  @apply bg-white/50 {} }
  .swiper-pagination.is-white .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-white {} }

.swiper-button {
  @apply flex-center gap-5 mt-5 xl:mt-0 {} }
  .swiper-button > * {
    @apply size-12 text-xl text-white rounded-1 bg-black/10 flex-center xl:size-15 xl:text-2xl {} }
    .swiper-button > *.swiper-button-disabled {
      @apply opacity-40 {} }
    .swiper-button > *.swiper-button-lock {
      @apply opacity-0 pointer-events-none {} }
  .swiper-button.is-white > * {
    @apply text-white bg-white/20 border-white hover:text-neutral-200 {} }
  .swiper-button.is-abs > * {
    @apply absolute top-1/2 -translate-y-1/2 z-1 {} }
  .swiper-button.is-abs .button-prev {
    @apply -left-12 xl:-left-20 {} }
  .swiper-button.is-abs .button-next {
    @apply -right-12 xl:-right-20 {} }
  .swiper-button.is-abs.is-top-20 > * {
    @apply top-[20%] {} }
  .swiper-button.is-abs.is-top-30 > * {
    @apply top-[30%] {} }
  .swiper-button.is-abs.is-top-40 > * {
    @apply top-[40%] {} }

.swiper-relative {
  @apply lg:px-10 xl:px-0 {} }
  .swiper-relative.is-page {
    @apply pb-10 lg:pb-0 {} }
  .swiper-relative .swiper-scrollbar {
    @apply h-2 bg-primary-1 rounded-full left-0 w-full {} }
    .swiper-relative .swiper-scrollbar .swiper-scrollbar-drag {
      @apply bg-primary-2 rounded-full {} }

.one-autoplay .swiper-wrapper {
  transition-timing-function: linear; }

.header-64 {
  @apply text-[32px] leading-none xl:text-64 {} }

.header-52 {
  @apply text-[30px] leading-1.125 xl:rem:text-[52px] {} }

.header-48 {
  @apply text-[30px] leading-snug xl:text-5xl {} }

.header-40 {
  @apply text-[28px] leading-snug xl:text-40 {} }

.section-header-32 {
  @apply text-[24px] leading-tight xl:text-32 {} }

.sub-header-24 {
  @apply text-[20px] leading-snug xl:text-2xl {} }

.sub-header-20 {
  @apply text-[18px] leading-snug xl:text-xl {} }

.body-18 {
  @apply text-[16px] leading-normal xl:text-lg {} }

.body-16 {
  @apply text-[16px] leading-tight xl:text-base {} }

.body-14 {
  @apply text-[14px] leading-tight xl:text-sm {} }

.label-12 {
  @apply text-[12px] leading-snug xl:text-sm {} }

.site-title {
  @apply header-40 font-bold {} }

.site-sub-title {
  @apply section-header-32 font-bold {} }

.site-desc {
  @apply body-16 font-normal text-neutral-950 space-y-4 xl:space-y-6 {} }

.full-content {
  @apply prose max-w-none {}  @apply body-16 font-normal text-neutral-950 {} }
  .full-content > * {
    @apply first:mt-0 last:mb-0 {} }
  .full-content p img {
    @apply m-0 {} }
  .full-content ul {
    @apply list-disc pl-4 {} }
  .full-content ol {
    @apply list-decimal pl-4 {} }

@layer base {
  html, body {
    font-size: 16px; }
  @screen xl {
    html, body {
      font-size: 1vw; } }
  body {
    @apply accent-blue-700 {}    @apply font-sans overflow-y-overlay text-main {} }
  main {
    @apply pt-21 xl:pt-25 {} }
  h1 {
    @apply text-3xl font-bold {} }
  h2 {
    @apply text-2xl font-bold {} }
  h3 {
    @apply text-xl font-bold {} }
  h4 {
    @apply text-lg font-bold {} }
  h5 {
    @apply text-base font-bold {} }
  h6 {
    @apply text-sm font-bold {} }
  a {
    @apply transition-all ease-linear duration-200 hover:text-current {} }
  img {
    @apply max-w-full inline {} }
  main, div {
    @apply xl:text-base {} }
  ::is(tbody, table) > tr:nth-child(odd) {
    background: #0001; }
  @media (prefers-reduced-motion) {
    *, *::before, *::after {
      animation-duration: 0s !important;
      /* additional recommendation */
      transition: none !important;
      scroll-behavior: auto !important; } } }

@layer components {
  @screen xs {
    .container {
      @apply max-w-full {} } }
  @screen sm {
    .container {
      @apply max-w-[96vw] {} } }
  @screen xl {
    .container {
      @apply max-w-[calc(1440/1920*100rem)] {} } }
  .equal-height .row > [class*=col] > * {
    @apply h-full {} }
  .equal-height .swiper-slide {
    @apply h-auto {} }
    .equal-height .swiper-slide > * {
      @apply h-full {} }
  .lozad-bg {
    @apply bg-center bg-no-repeat bg-cover {} }
  .img-cover {
    @apply relative overflow-hidden {} }
    .img-cover img {
      @apply w-full h-full object-cover {} }
  .img-contain {
    @apply relative overflow-hidden {} }
    .img-contain img {
      @apply w-full h-full object-contain {} }
  .img-zoom {
    @apply relative overflow-hidden {} }
    .img-zoom img {
      @apply w-full h-full object-cover transition-all duration-300 ease-linear hover:scale-105 {} }
  .item-hover .image {
    @apply relative overflow-hidden {} }
    .item-hover .image img {
      @apply transition-all {} }
  .item-hover:hover .image img {
    @apply scale-110 {} } }

@layer utilities {
  .container-fuild {
    @apply px-4 {} }
  .section {
    @apply py-10 xl:py-15 {} }
  .section-t {
    @apply pt-10 xl:pt-15 {} }
  .section-b {
    @apply pb-10 xl:pb-15 {} }
  .section-small {
    @apply py-8 xl:py-10 {} }
  .section-t-small {
    @apply pt-8 xl:pt-10 {} }
  .section-b-small {
    @apply pb-8 xl:pb-10 {} }
  .section-large {
    @apply py-15 xl:py-20 {} }
  .section-t-large {
    @apply pt-15 xl:pt-20 {} }
  .section-b-large {
    @apply pb-15 xl:pb-20 {} }
  .row {
    @apply flex flex-wrap -mx-4 xl:-mx-5 {} }
    .row > * {
      @apply px-4 xl:px-5 {} }
  .no-gutters {
    @apply mx-0 {} }
    .no-gutters > * {
      @apply px-0 {} }
  .swiper-relative {
    @apply relative {} }
  .writing-mode-tb-rl {
    writing-mode: vertical-rl;
    text-orientation: mixed; }
  .media-scale {
    @apply relative block h-0 overflow-hidden {} }
    .media-scale img, .media-scale iframe, .media-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .img-scale {
    @apply relative block h-0 overflow-hidden {} }
    .img-scale img {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .iframe-scale {
    @apply relative block h-0 overflow-hidden {} }
    .iframe-scale iframe {
      @apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .video-scale {
    @apply relative block h-0 overflow-hidden {} }
    .video-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .bg-bright-grey {
    @apply backdrop-blur-25 {} }
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 {} }
  .filter-white {
    @apply brightness-0 invert {} }
  .img-zoom-in:hover img {
    @apply scale-110 {} }
  .hover-underline {
    @apply relative {}    background: linear-gradient(0deg, theme("colors.current"), theme("colors.current")) no-repeat right bottom/0 theme("spacing[px]");
    transition: background-size 350ms; }
    .hover-underline:hover {
      background-size: 100% theme("spacing[px]");
      background-position-x: left; }
  .hover-underline-active {
    background-size: 100% theme("spacing[px]");
    background-position-x: left; }
  .desktop-only {
    @apply hidden lg:block {} }
  .mobile-only {
    @apply block lg:hidden {} }
  .text-xs {
    @apply text-[14px] xl:text-[calc(12/1920*100rem)] {} }
  .tsn {
    @apply transition-all duration-300 ease-in-out {} } }

.banner-child .image {
  @apply aspect-[1920/940] md:aspect-[1920/540] {} }

.banner-child .container {
  @apply absolute-center w-full z-1 {} }

.banner-child .caption {
  @apply rem:max-w-[620px] p-5 bg-primary-1/70 border-l-4 border-primary-2 xl:p-10 {} }

.banner-child .banner-title {
  background: -webkit-linear-gradient(-90deg, #056839 0%, #5EA53D 60%, #8FC63F 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  @apply rem:text-[30px] font-black text-white uppercase bg-clip-text xl:rem:text-[52px] {} }

.product-detail-page .banner-child, .recruitment-detail-page .banner-child, .news-detail-page .banner-child {
  @apply hidden {} }

.video-container {
  @apply cursor-pointer {} }
  .video-container .play-icon {
    @apply absolute-center rounded-full bg-primary-2/[0.5] text-white flex items-center justify-center rem:w-[80px] rem:h-[80px] z-2 rem:text-[40px] {} }

.home-banner .image {
  @apply aspect-[1280/2580] md:aspect-[1920/860] {} }
  .home-banner .image .mb {
    @apply md:hidden {} }
    .home-banner .image .mb ~ img {
      @apply hidden md:block {} }

.home-banner .container {
  @apply absolute-center w-full z-1 pointer-events-none {} }
  .home-banner .container > * > * {
    @apply pointer-events-auto {} }

.home-banner .swiper-slide .container {
  @apply top-[22%] md:top-1/2 {} }

.home-banner .caption {
  @apply rem:max-w-[620px] p-5 bg-white/20 border-l-4 border-primary-2 xl:p-10 {} }

.home-banner .banner-title {
  background: -webkit-linear-gradient(-90deg, #056839 0%, #5EA53D 60%, #8FC63F 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 6px transparent;
  @apply rem:text-[34px] font-black text-white uppercase bg-clip-text xl:rem:text-[52px] {} }

.home-banner .swiper-button {
  @apply w-full justify-between hidden xl:flex {} }
  .home-banner .swiper-button > * {
    @apply bg-black/40 {} }
  .home-banner .swiper-button .button-prev {
    @apply -translate-x-25 {} }
  .home-banner .swiper-button .button-next {
    @apply translate-x-25 {} }

.home-banner .swiper-pagination {
  @apply bottom-20 {} }
  .home-banner .swiper-pagination .swiper-pagination-bullet {
    @apply w-5 h-1 rounded-full bg-white opacity-50 xl:w-10 {} }
  .home-banner .swiper-pagination .swiper-pagination-bullet-active {
    @apply bg-white opacity-100 xl:w-20 {} }

.about-1-section {
  @apply xl:pb-26 {} }
  .about-1-section .block-content {
    @apply flex flex-col gap-10 lg:flex-row xl:gap-15 {} }
  .about-1-section .image-content {
    @apply aspect-[320/470] rem:w-[320px] rounded-5 mx-auto {} }
  .about-1-section .image {
    @apply aspect-[457/608] rounded-6 {} }
  .about-1-section .site-desc {
    @apply lg:flex-1 {} }
  .about-1-section .bg {
    @apply hidden absolute bottom-0 xl:block {} }
  .about-1-section .about-1-bg {
    @apply left-0 rem:w-[820px] aspect-[820/222] {} }
  .about-1-section .about-2-bg {
    @apply right-11 rem:w-[621px] aspect-[621/186] {} }

@screen xl {
  .about-1-section .row {
    @apply -mx-3 {} }
    .about-1-section .row > * {
      @apply px-3 {} }
  .about-1-section .col-cus-1 {
    @apply w-[calc(944/1424*100%)] {} }
  .about-1-section .col-cus-2 {
    @apply w-[calc(480/1424*100%)] {} } }

.about-2-item {
  @apply bg-white rounded-5 p-3 flex flex-col gap-5 tsn md:flex-row md:items-center xl:gap-10 xl:p-5 {} }
  .about-2-item * {
    @apply tsn {} }
  .about-2-item .image {
    @apply aspect-[280/186] rounded-5 md:rem:w-[280px] {} }
  .about-2-item .caption {
    @apply flex-1 {} }
  .about-2-item .year-title {
    @apply section-header-32 font-bold text-primary-1 {} }
  .about-2-item .desc {
    @apply body-16 font-normal text-neutral-950 {} }

.about-2-thumbs {
  @apply relative {} }
  .about-2-thumbs::before {
    content: '';
    @apply absolute left-0 bottom-2 w-full h-px bg-white/30 {} }
  .about-2-thumbs .year-dot {
    @apply sub-header-24 font-bold text-white text-center h-10 mb-6 flex-center cursor-pointer {} }
    .about-2-thumbs .year-dot::before {
      content: '';
      @apply absolute left-1/2 -translate-x-1/2 bottom-1 size-2 bg-white rounded-full z-1 {} }
    .about-2-thumbs .year-dot::after {
      content: '';
      @apply absolute left-1/2 -translate-x-1/2 bottom-0 size-4 bg-white rounded-full opacity-0 {} }
  .about-2-thumbs .swiper-slide-thumb-active .year-dot {
    @apply text-primary-2 text-32 {} }
    .about-2-thumbs .swiper-slide-thumb-active .year-dot::before {
      @apply rem:size-[10px] rem:bottom-[3px] bg-primary-2 {} }
    .about-2-thumbs .swiper-slide-thumb-active .year-dot::after {
      @apply opacity-100 {} }

.about-2-top {
  @apply rem:max-w-[760px] mx-auto {} }

.about-2-section {
  @apply overflow-hidden xl:pt-25 xl:pb-27 {} }

@screen xl {
  .about-2-section .swiper-button > * {
    @apply absolute top-1/2 -translate-y-1/2 z-1 {} }
  .about-2-section .swiper-button .button-prev {
    @apply -left-25 {} }
  .about-2-section .swiper-button .button-next {
    @apply -right-25 {} } }

@screen xl {
  .about-2-section .about-2-top {
    @apply overflow-visible {} }
    .about-2-section .about-2-top .about-2-item {
      @apply bg-transparent px-15 rem:h-[226px] {} }
      .about-2-section .about-2-top .about-2-item .image {
        @apply hidden {} }
      .about-2-section .about-2-top .about-2-item .year-title {
        @apply header-48 text-white text-right {} }
      .about-2-section .about-2-top .about-2-item .desc {
        @apply hidden {} }
    .about-2-section .about-2-top .swiper-slide-active .about-2-item {
      @apply bg-white p-5 {} }
      .about-2-section .about-2-top .swiper-slide-active .about-2-item .image {
        @apply block {} }
      .about-2-section .about-2-top .swiper-slide-active .about-2-item .year-title {
        @apply text-left section-header-32 text-primary-1 {} }
      .about-2-section .about-2-top .swiper-slide-active .about-2-item .desc {
        @apply block {} }
    .about-2-section .about-2-top .swiper-slide-active + .swiper-slide .about-2-item .year-title {
      @apply text-left {} } }

.about-3-item {
  @apply py-6 border-b border-neutral-200 last:border-none first:pt-0 last:pb-0 {} }
  .about-3-item .icon {
    @apply size-13 {} }
  .about-3-item .title {
    @apply section-header-32 font-bold text-primary-1 {} }
  .about-3-item .desc {
    @apply body-16 font-normal text-neutral-950 {} }

.about-3-section .image {
  @apply aspect-[680/606] rounded-6 xl:rem:max-w-[680px] {} }

.about-3-section .about-3-bg {
  @apply hidden h-full absolute top-0 left-0 rem:w-[700px] -z-1 lg:block {} }

.about-3-section .block-content {
  @apply xl:pl-10 {} }

.about-4-item {
  @apply relative rounded-4 overflow-hidden {} }
  .about-4-item .image {
    @apply aspect-[338/450] {} }
  .about-4-item .caption {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    @apply absolute bottom-0 left-0 w-full p-5 xl:py-6 {} }
  .about-4-item .title {
    @apply sub-header-20 font-bold text-white text-center {} }

.about-4-list {
  @apply grid grid-cols-1 rem:gap-[15px] sm:grid-cols-2 lg:grid-cols-4 {} }

.factory-item {
  @apply px-3 rem:h-[127px] flex-center bg-white border border-neutral-200 rounded-2 grayscale tsn opacity-30 {} }

.factory-slider .swiper-button {
  @apply mb-10 xl:mb-0 {} }

@screen xl {
  .factory-slider .swiper-button > * {
    @apply absolute top-1/2 -translate-y-1/2 z-1 {} }
  .factory-slider .swiper-button .button-prev {
    @apply -left-25 {} }
  .factory-slider .swiper-button .button-next {
    @apply -right-25 {} } }

.factory-slider .swiper-wrapper {
  @apply items-center {} }

.factory-slider .swiper-slide {
  @apply rem:w-[250px] py-5 rem:h-[190px] flex-center xl:py-10 xl:rem:h-[230px] {} }
  .factory-slider .swiper-slide.swiper-slide-active .factory-item {
    box-shadow: 4px 4px 20px 12px rgba(0, 0, 0, 0.08);
    @apply grayscale-0 opacity-100 rounded-3 h-full {} }

@screen md {
  .factory-slider .swiper-slide.swiper-slide-active {
    @apply rem:w-[302px] {} } }

.album-slider .swiper {
  @apply xl:overflow-visible {} }

.album-slider .image {
  @apply aspect-[1160/610] rounded-6 relative {} }
  .album-slider .image::before {
    content: '';
    backdrop-filter: blur(6px);
    @apply absolute top-0 left-0 w-full h-full bg-white/60 z-1 pointer-events-none {} }

.album-slider .caption {
  @apply tsn mt-5 {} }

.album-slider .title {
  @apply sub-header-20 font-bold text-primary-1 text-center {} }

@screen xl {
  .album-slider .swiper-button > * {
    @apply absolute top-1/2 -translate-y-1/2 z-1 {} }
  .album-slider .swiper-button .button-prev {
    @apply -left-30 {} }
  .album-slider .swiper-button .button-next {
    @apply -right-30 {} } }

.album-slider .swiper-slide.swiper-slide-active .image::before {
  @apply hidden {} }

.about-6-item {
  @apply pb-3 pt-5 border-b-2 border-neutral-100 {} }
  .about-6-item .title {
    @apply sub-header-20 font-normal text-neutral-950 {} }
  .about-6-item .number {
    @apply mt-3 rem:text-[30px] font-black text-primary-1 flex items-center xl:rem:text-[44px] xl:leading-[calc(60/44)] {} }
  .about-6-item .count-up {
    @apply mr-1 {} }

.about-6-list {
  @apply grid grid-cols-2 gap-5 xl:gap-y-6 xl:gap-x-16 {} }
  .about-6-list .about-6-item:nth-child(1), .about-6-list .about-6-item:nth-child(2) {
    @apply pt-0 {} }

.about-6-section .image {
  @apply aspect-[560/696] rounded-5 {} }

.about-6-section .block-content {
  @apply xl:pl-10 {} }

.customers-item {
  @apply rem:h-[98px] rounded-3 border border-neutral-200 p-3 bg-white {} }
  .customers-item img {
    @apply tsn {} }
  .customers-item:hover img {
    @apply scale-110 {} }

@screen xl {
  .about-7-section .swiper-button > * {
    @apply absolute top-1/2 -translate-y-1/2 z-1 {} }
  .about-7-section .swiper-button .button-prev {
    @apply -left-25 {} }
  .about-7-section .swiper-button .button-next {
    @apply -right-25 {} } }

.partner-item {
  @apply h-24 flex-center {} }

.partner-slider .swiper-slide {
  @apply rem:w-[192px] {} }

.global-breadcrumb {
  @apply bg-neutral-50 {} }
  .global-breadcrumb .breadcrumb {
    @apply flex flex-wrap items-center py-3 gap-x-3 gap-y-2 {} }
    .global-breadcrumb .breadcrumb a {
      @apply block body-14 font-bold text-primary-1 {} }
    .global-breadcrumb .breadcrumb li {
      @apply flex items-center last:after:hidden {} }
      .global-breadcrumb .breadcrumb li::after {
        content: '';
        @apply ml-3 w-px h-6 bg-primary-1 {} }
      .global-breadcrumb .breadcrumb li:first-child a {
        @apply text-0 {} }
        .global-breadcrumb .breadcrumb li:first-child a span {
          @apply hidden {} }
        .global-breadcrumb .breadcrumb li:first-child a::before {
          @apply font-awesome content-['\f015'] text-primary-1 text-base font-bold leading-none flex-center {} }

.cmsadminpanel .ddviewmode {
  @apply h-[24px] text-[12px] py-1 px-2 pr-5 leading-none {} }

.searchbox {
  @apply relative container px-0 {} }
  .searchbox input {
    @apply w-full h-12 border-neutral-200 border rounded-1 pl-5 pr-12 text-base text-neutral-700 placeholder:text-neutral-200 focus:outline-none focus:border-primary-1 {} }
  .searchbox button {
    @apply absolute top-1/2 -translate-y-1/2 right-1 z-1 w-11 h-11 flex-center text-xl text-secondary-700 {} }

.suggestsearch {
  display: none !important; }

.searchresults .search-page {
  @apply py-15 xl:py-20 {} }

.searchresults .page-header h1 {
  @apply uppercase {}  @apply relative w-max max-w-full pb-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px {} }

.searchresults .searchresultsummary {
  @apply text-[11px] xl:text-[13px] mb-3 {} }

.searchresults .searchcontrols .form-inline {
  @apply relative {} }

.searchresults .searchcontrols .form-group {
  @apply hidden first:block last:block last:absolute last:w-12 last:h-full last:top-0 last:right-0 last:z-1 last:text-[0] last:mb-0 last:flex-center {}  @apply last:before:absolute last:before:w-full last:before:h-full last:before:font-awesome last:before:content-['\f002'] last:before:text-primary-1 last:before:text-2xl last:before:flex-center last:before:pointer-events-none {} }
  .searchresults .searchcontrols .form-group:first-child input {
    @apply pr-14 {} }
  .searchresults .searchcontrols .form-group:last-child input {
    @apply z-2 w-12 h-full relative top-0 right-0 opacity-0 {} }

.searchresults .searchresults > .modulepager {
  @apply hidden {} }
  .searchresults .searchresults > .modulepager:last-child {
    @apply block {} }

.searchresults .searchresultlist {
  @apply px-1 xl:px-5 {} }

.searchresults .searchresult {
  @apply mt-10 {} }
  .searchresults .searchresult h3 a {
    @apply text-xl hover:underline hover:text-primary-1 {} }
  .searchresults .searchresult .searchresultdesc {
    @apply mt-1 text-base {} }
  .searchresults .searchresult hr {
    @apply hidden {} }

.login-box {
  @apply py-25 px-10 xl:py-30 {} }
  .login-box .card {
    @apply bg-neutral-200 mx-auto p-5 {} }

@screen xl {
  .login-box .card {
    @apply max-w-[calc(750/1920*100rem)] px-[calc(95/1920*100rem)] pt-7 pb-15 {} } }
  .login-box .input-group {
    @apply mb-5 {} }
    .login-box .input-group input {
      @apply border-white bg-white w-full rounded-1 h-12 text-neutral-700 text-base px-5 placeholder:text-neutral-200 {} }
  .login-box .input-group-append {
    @apply hidden {} }
  .login-box .btn.btn-primary-1.btn-block, .login-box .btn.btn-default.createuserbutton {
    @apply cursor-pointer bg-black border-black transition-all h-11 text-center text-white font-normal uppercase text-sm px-10 rounded-full xl:min-w-[calc(183/1920*100rem)] xl:hover:bg-primary-1 xl:hover:border-primary-1 {} }
  .login-box .social-login {
    @apply hidden {} }
  .login-box .row.items-center {
    @apply justify-between {} }
  .login-box a {
    @apply text-base xl:text-sm {} }

.wrap-register > div > div {
  @apply shadow rounded-5 overflow-hidden bg-white mx-auto p-5 {} }

@screen xl {
  .wrap-register > div > div {
    @apply max-w-[calc(600/1920*100rem)] p-7 {} } }

.wrap-register .settingrow .RadInput .riTextBox {
  @apply w-full bg-neutral-400 border border-solid border-neutral-400 rounded-1 h-12 px-5 text-base text-neutral-900 leading-normal xl:text-sm {} }

.login-logo, .pagetitle {
  @apply text-2xl font-light text-secondary-700 uppercase leading-tight text-center mb-8 {} }

.login-box-msg {
  @apply text-base leading-[1.38] text-secondary-700 font-normal mb-5 {} }

.icheck-primary-1 {
  @apply form-group text-base space-x-3 {} }

.login-download-section .login-box {
  @apply p-0 xl:p-0 {} }
  .login-download-section .login-box .btn-download-submit {
    @apply w-full {} }

.login-download-section .module-description {
  @apply text-base text-secondary-700 font-normal {} }

.career-desc {
  @apply p-5 border border-primary-2 rounded-2 xl:p-10 xl:rounded-4 {} }
  .career-desc .desc {
    @apply body-16 font-normal text-neutral-950 rem:mt-[15px] {} }
  .career-desc ul {
    @apply list-disc pl-4 rem:space-y-[15px] {} }
    .career-desc ul li {
      @apply marker:text-neutral-300 pl-1 {} }

.career-content-item {
  @apply pt-6 border-t border-neutral-200 body-16 font-normal text-neutral-950 first:border-none first:pt-0 last:pb-0 rem:space-y-[15px] {} }
  .career-content-item h2 {
    @apply sub-header-20 font-bold text-neutral-950 {} }
  .career-content-item ul {
    @apply list-disc pl-4 rem:space-y-[15px] {} }
    .career-content-item ul li {
      @apply marker:text-neutral-300 pl-1 {} }
  .career-content-item table {
    @apply w-full border border-neutral-100 {} }
    .career-content-item table tr {
      @apply odd:bg-neutral-50 {} }
  .career-content-item td {
    @apply body-16 font-normal text-black p-2 align-top xl:px-5 xl:rem:py-[10px] {}    @apply first:font-bold last:pl-3 xl:first:w-[22%] {} }

.career-content-list {
  @apply space-y-6 {} }

.box-career-detail-button {
  @apply flex flex-col gap-6 bg-neutral-50 rounded-4 p-5 xl:p-8 {} }
  .box-career-detail-button a {
    @apply w-full justify-between {} }

.jobs-item {
  @apply relative py-4 space-y-2 body-16 font-normal text-neutral-950 border-t border-neutral-100 first:border-none {} }
  .jobs-item .title {
    @apply sub-header-20 font-bold text-neutral-950 tsn {} }
  .jobs-item .news-date {
    @apply text-primary-1 {} }
  .jobs-item:hover .title {
    @apply text-primary-1 {} }

.load-more-item {
  @apply hidden {} }

.career-2-item {
  @apply relative {} }
  .career-2-item .image {
    @apply aspect-[440/246] rounded-3 bg-black xl:rounded-6 {} }
    .career-2-item .image img {
      @apply opacity-70 {} }
  .career-2-item .caption {
    @apply absolute bottom-8 left-0 w-full z-1 {} }
  .career-2-item .title {
    @apply sub-header-24 font-bold text-white pl-6 py-3 border-l-6 border-primary-2 {} }

.career-2-list {
  @apply grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-10 {} }

.career-3-table {
  @apply overflow-x-auto xl:overflow-visible {} }
  .career-3-table table {
    @apply w-full rem:min-w-[800px] xl:min-w-full {} }
  .career-3-table th {
    @apply border border-neutral-50 sub-header-20 font-bold text-white bg-primary-1 uppercase p-2 xl:px-5 xl:rem:py-[10px] {} }
    .career-3-table th:nth-child(1) {
      @apply w-20 {} }
    .career-3-table th:nth-child(2) {
      @apply text-left {} }
    .career-3-table th:nth-child(3) {
      @apply w-[21%] {} }
    .career-3-table th:nth-child(4) {
      @apply w-[21%] {} }
  .career-3-table td {
    @apply border border-neutral-50 body-16 font-normal text-neutral-950 text-center p-2 xl:px-5 xl:py-3 {}    @apply first:font-bold {} }
    .career-3-table td:nth-child(2) {
      @apply text-left {} }
    .career-3-table td a {
      @apply text-current hover-underline hover:text-primary-1 {} }
  .career-3-table tbody tr {
    @apply even:bg-neutral-50 {} }

.contact-list {
  @apply grid grid-cols-2 gap-5 sm:grid-cols-3 lg:grid-cols-3 xl:gap-10 {} }

.contact-item {
  @apply bg-neutral-50 rounded-3 p-5 body-16 font-normal text-neutral-950 text-center xl:p-10 xl:rounded-6 {} }
  .contact-item .icon {
    @apply size-12 bg-primary-1 rounded-2 flex-center mx-auto text-xl text-white mb-3 {} }
  .contact-item .title {
    @apply sub-header-20 font-bold text-neutral-950 mb-5 {} }
  .contact-item a {
    @apply hover:text-primary-1 {}    word-wrap: break-word; }

.contact-maps .iframe-scale {
  @apply pt-full rounded-3 overflow-hidden xl:rounded-6 {} }

.contact-form {
  @apply bg-neutral-50 rounded-3 p-5 xl:p-10 xl:rounded-6 xl:h-full {} }

header {
  @apply fixed top-0 left-0 right-0 z-998 w-full bg-white shadow-drop-shadow-light xl:h-25 {} }
  header .header-wrap {
    @apply flex justify-between items-center gap-5 py-3 xl:py-5 xl:rem:gap-[54px] {} }
  header .header-left {
    @apply hidden xl:block {} }
  header .header-right, header .header-left {
    @apply flex-1 {} }
  header .header-right, header .button-group {
    @apply flex items-center justify-end {} }
  header .header-right {
    @apply gap-5 xl:gap-25 {} }
  header .logo {
    @apply h-15 {} }
    header .logo p, header .logo a, header .logo .ModuleContent {
      @apply h-full flex-center {} }
    header .logo img {
      @apply w-auto h-full object-contain {} }
  header .button-group {
    @apply gap-6 {} }
  header .main-menu {
    @apply hidden items-center gap-5 xl:gap-23 xl:flex {} }
    header .main-menu a {
      @apply sub-header-20 font-bold text-neutral-950 py-1 hover-underline hover:text-primary-1 {} }
    header .main-menu li.active a {
      @apply text-primary-1 hover-underline-active {} }
  header .header-right .main-menu {
    @apply justify-end xl:gap-25 {} }
  header .button-search {
    @apply text-2xl text-neutral-950 tsn hover:text-primary-1 cursor-pointer {} }

.mobile-wrap {
  @apply fixed top-0 w-screen h-screen bg-white shadow z-100 pt-32 pl-4 pb-4 max-w-xs -left-full opacity-0 transition-all duration-500 ease-in-out pointer-events-none xl:hidden {} }
  .mobile-wrap .navbar-nav-list {
    @apply pr-4 flex flex-col gap-5 {} }
  .mobile-wrap .main-menu {
    @apply flex flex-col gap-5 {} }
    .mobile-wrap .main-menu a {
      @apply sub-header-20 font-bold text-neutral-950 py-1 hover-underline hover:text-primary-1 {} }
    .mobile-wrap .main-menu li.active a {
      @apply text-primary-1 hover-underline-active {} }
  .mobile-wrap.active {
    @apply left-0 opacity-100 pointer-events-auto {} }

.search-wrap {
  @apply fixed top-32 left-0 right-0 w-full z-100 hidden px-10 xl:rem:top-[160px] {} }

.wpml-ls.wpml-ls-legacy-dropdown-click {
  @apply w-full relative {} }
  .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li {
    @apply flex-center {} }
    .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > a {
      @apply flex-center sub-header-20 font-bold text-neutral-950 bg-none bg-transparent p-0 border-0 {} }
      .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > a::after {
        @apply font-awesome content-['\f0d7'] text-xs font-bold text-current ml-1 border-none flex static {} }
    .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > .wpml-ls-sub-menu {
      @apply border-none absolute top-full bg-white hidden p-5 {} }
      .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li > .wpml-ls-sub-menu a {
        @apply flex-center sub-header-20 font-bold text-neutral-950 bg-none bg-transparent p-0 border-0 {} }
    .wpml-ls.wpml-ls-legacy-dropdown-click > ul > li:hover > .wpml-ls-sub-menu {
      @apply block {} }

.home-1-item {
  @apply relative py-6 {} }
  .home-1-item .icon {
    @apply size-15 flex-center mx-auto {} }
  .home-1-item .number {
    @apply mt-3 header-40 font-bold text-primary-1 text-center flex-center {}    @apply leading-[1.2] {} }
  .home-1-item .title {
    @apply mt-2 body-16 font-normal text-neutral-950 text-center rem:max-w-[184px] mx-auto {} }
  .home-1-item .line {
    @apply flex-center gap-1 absolute top-1/2 -translate-y-1/2 -right-20 hidden {} }
    .home-1-item .line span {
      @apply w-6 h-2 bg-[#8DC63F] opacity-50 {} }
      .home-1-item .line span:nth-child(2) {
        @apply bg-primary-1 opacity-100 rounded-tl-[2px] rounded-br-[2px] w-2 {} }

@screen xl {
  .home-1-item .line {
    @apply flex {} }
  .home-1-item:nth-child(4n) .line {
    @apply hidden {} } }

.home-1-list {
  @apply grid grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-4 xl:gap-x-23 xl:gap-y-8 {} }

@screen xl {
  .home-1-section {
    background-position: center bottom !important;
    @apply rem:pt-[70px] {} } }

.home-2-section .swiper {
  @apply xl:overflow-visible {} }

.home-2-section .swiper-relative {
  @apply pb-5 xl:pb-10 {} }

.home-4-section .site-desc {
  @apply text-white {} }

.home-4-section .image {
  @apply rem:max-w-[1169px] mx-auto {} }
  .home-4-section .image svg {
    @apply w-full h-full object-contain {} }

.home-5-item {
  @apply flex flex-col md:flex-row md:even:flex-row-reverse {} }
  .home-5-item .image {
    @apply aspect-[700/400] {} }
  .home-5-item .caption {
    @apply px-5 py-10 lg:p-15 xl:p-20 md:flex md:flex-col md:justify-center {} }
  .home-5-item .news-date {
    @apply text-white {} }
  .home-5-item .title {
    @apply sub-header-24 font-bold text-white line-clamp-2 {} }
  .home-5-item .desc {
    @apply body-16 font-normal text-white line-clamp-2 {} }
  .home-5-item .button a {
    @apply bg-white {}    @apply hover:bg-[#5C8C24] hover:text-white hover:border-[#5C8C24] {} }
  .home-5-item:nth-child(1) .news-date {
    @apply text-neutral-500 {} }
  .home-5-item:nth-child(1) .title {
    @apply text-primary-1 {} }
  .home-5-item:nth-child(1) .desc {
    @apply text-neutral-900 {} }
  .home-5-item:nth-child(1) .button a {
    @apply bg-primary-2 border-none text-white {}    @apply hover:bg-[#5C8C24] {} }

@screen md {
  .home-5-item > * {
    @apply w-1/2 {} } }

.home-5-list {
  @apply rounded-6 overflow-hidden {} }

.home-5-section .tabslet-tabs {
  @apply flex justify-center rem:max-w-[480px] mx-auto {} }
  .home-5-section .tabslet-tabs a {
    @apply flex-center text-center border border-primary-1 py-2 px-3 body-18 font-bold text-primary-1 bg-white tsn min-h-11 h-full xl:px-5 {} }
  .home-5-section .tabslet-tabs li {
    @apply basis-full {} }
    .home-5-section .tabslet-tabs li.active a {
      @apply bg-primary-2 border-primary-2 text-white {} }
    .home-5-section .tabslet-tabs li:first-child a {
      @apply rounded-l-full {} }
    .home-5-section .tabslet-tabs li:last-child a {
      @apply rounded-r-full {} }

.home-7-section .image {
  @apply rem:w-[740px] h-full mt-5 max-w-full {} }
  .home-7-section .image .ModuleContent {
    @apply h-full {} }

@screen xl {
  .home-7-section .image {
    @apply absolute top-0 left-0 mt-0 {} } }

.home-7-section .block-content {
  @apply xl:rem:max-w-[840px] xl:ml-auto xl:mr-0 {} }

@media only screen and (max-width: 575.98px) {
  .home-7-section .site-title {
    @apply text-2xl {} } }

.news-flex {
  @apply flex items-center gap-5 {} }
  .news-flex .line {
    @apply flex-1 h-px bg-primary-1/10 {} }

.box-other-news {
  @apply sticky top-25 {} }
  .box-other-news .box-body {
    @apply space-y-5 {} }

.news-date {
  @apply body-14 font-normal text-neutral-500 {} }

.news-category {
  @apply body-14 font-bold text-primary-2 {} }

.news-item {
  @apply relative flex flex-col gap-5 {} }
  .news-item .image {
    @apply aspect-square rounded-3 xl:rounded-6 {} }
  .news-item .caption {
    @apply md:flex md:flex-col md:justify-center {} }
  .news-item .news-date {
    @apply mb-2 {} }
  .news-item .title {
    @apply sub-header-20 font-bold text-primary-1 line-clamp-3 {} }
  .news-item .desc {
    @apply mt-2 body-16 font-normal text-neutral-950 line-clamp-3 {} }
  .news-item .button a {
    @apply relative z-[3] {} }
  .news-item.is-big {
    @apply gap-0 rounded-3 overflow-hidden shadow-drop-shadow-light xl:rounded-6 {} }
    .news-item.is-big .image {
      @apply rounded-none {} }
    .news-item.is-big .caption {
      @apply p-5 {} }

@screen md {
  .news-item.is-big {
    @apply flex-row {} }
    .news-item.is-big > * {
      @apply w-1/2 {} } }

@screen lg {
  .news-item.is-big .image {
    @apply w-2/3 aspect-[700/400] {} }
  .news-item.is-big .caption {
    @apply flex-1 w-auto {} } }

@screen xl {
  .news-item.is-big .caption {
    @apply px-10 {} }
  .news-item.is-big .title {
    @apply sub-header-24 {} } }
  .news-item.is-hor {
    @apply flex-row {} }
    .news-item.is-hor .image {
      @apply w-1/3 rounded-3 {} }
    .news-item.is-hor .caption {
      @apply flex-1 {} }
    .news-item.is-hor .title {
      @apply body-16 {} }

.news-list {
  @apply grid grid-cols-2 gap-5 sm:grid-cols-3 lg:grid-cols-4 xl:gap-10 {} }

.head-flex {
  @apply flex flex-col gap-5 md:flex-row md:items-center md:justify-between {} }

.site-nav .nav-list {
  @apply flex flex-wrap items-center gap-5 {} }
  .site-nav .nav-list a {
    @apply body-18 font-bold text-neutral-950 hover:text-primary-1 hover-underline {} }
  .site-nav .nav-list li.active a {
    @apply text-primary-1 {} }

.product-top-slider .image {
  @apply aspect-[660/495] rounded-3 xl:rounded-6 {} }

.product-thumbs-slider .image {
  @apply aspect-[100/75] rounded-2 {} }

.product-thumbs-slider .swiper-slide {
  @apply opacity-70 {} }
  .product-thumbs-slider .swiper-slide::before {
    content: '';
    @apply absolute inset-0 border border-primary-1 opacity-0 rounded-2 z-1 pointer-events-none {} }
  .product-thumbs-slider .swiper-slide.swiper-slide-thumb-active {
    @apply opacity-100 {} }
    .product-thumbs-slider .swiper-slide.swiper-slide-thumb-active::before {
      @apply opacity-100 {} }

.product-table {
  @apply rem:pr-[18px] rem:max-h-[460px] overflow-y-auto {} }
  .product-table::-webkit-scrollbar {
    width: 2px; }
  .product-table::-webkit-scrollbar-track {
    background-color: theme("colors.neutral.100"); }
  .product-table::-webkit-scrollbar-thumb {
    background-color: theme("colors.secondary.900"); }
  .product-table::-webkit-scrollbar-thumb:hover {
    background-color: theme("colors.secondary.800"); }
  .product-table table {
    @apply w-full {} }
  .product-table tr {
    @apply even:bg-neutral-50 {} }
  .product-table td {
    @apply body-16 font-normal text-black align-top px-3 py-2 xl:rem:py-[10px] {}    @apply first:font-bold first:w-[38%] xl:first:px-5 xl:last:pl-0 {} }

.product-detail-section .swiper-button {
  @apply absolute bottom-3 left-3 z-1 mt-0 xl:left-6 xl:bottom-6 {} }

.product-detail-section .product-slider {
  @apply lg:rem:max-w-[660px] {} }

.product-detail-section .block-content {
  @apply lg:rem:max-w-[660px] lg:ml-auto lg:mr-0 {} }

.product-detail-other-section .product-item {
  @apply bg-white hover:bg-primary-1 {} }

@screen xl {
  .product-detail-other-section .swiper-button > * {
    @apply absolute top-1/2 -translate-y-1/2 z-1 {} }
  .product-detail-other-section .swiper-button .button-prev {
    @apply -left-25 {} }
  .product-detail-other-section .swiper-button .button-next {
    @apply -right-25 {} } }

.link-overlay {
  @apply absolute inset-0 z-2 size-full !important {} }

.product-item {
  @apply relative flex flex-col gap-3 p-3 rounded-3 bg-neutral-50 tsn xl:p-5 xl:gap-5 xl:rounded-6 {}  @apply hover:bg-primary-1 {} }
  .product-item * {
    @apply tsn {} }
  .product-item .image {
    @apply aspect-square rounded-3 bg-neutral-50 xl:rounded-6 {} }
  .product-item .title {
    @apply sub-header-20 font-normal text-primary-1 text-center {} }
  .product-item .link {
    @apply flex-center gap-1 text-[12px] font-bold text-[#818998] tsn hover:text-primary-2 {} }
  .product-item .caption {
    @apply flex flex-col justify-between flex-1 {} }
  .product-item:hover {
    box-shadow: 4px 4px 24px 0px rgba(102, 226, 41, 0.4); }
    .product-item:hover .title {
      @apply text-white {} }
    .product-item:hover .link {
      @apply text-white hover:text-primary-2 {} }

.product-list {
  @apply grid grid-cols-2 gap-5 sm:grid-cols-3 lg:grid-cols-4 xl:gap-10 {} }

.box-category {
  @apply rounded-3 xl:rounded-6 overflow-hidden shadow-drop-shadow-light {} }
  .box-category .box-title {
    @apply sub-header-24 font-bold text-white bg-primary-1 py-3 xl:rem:py-[14px] px-4 xl:rem:px-[30px] {} }
  .box-category .box-body {
    @apply px-4 py-2 xl:px-5 {} }
    .box-category .box-body a {
      @apply body-16 font-normal text-black block py-3 tsn relative {}      @apply hover:text-primary-2 hover:pl-7 hover:before:opacity-100 {} }
      .box-category .box-body a::before {
        @apply font-awesome content-['\f178'] absolute left-0 top-1/2 -translate-y-1/2 text-current text-base font-normal opacity-0 tsn {} }
    .box-category .box-body li {
      @apply border-b border-neutral-200 last:border-none {} }
      .box-category .box-body li.active a {
        @apply text-primary-2 pl-7 before:opacity-100 {} }

.sitemap {
  @apply py-15 {} }

.sitemap-heading h1 {
  @apply site-title text-center uppercase mx-auto {}  @apply relative w-max max-w-full pb-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px before:bg-gradient {} }

.AspNet-TreeView > ul {
  @apply sm:flex sm:flex-wrap xl:overflow-hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root {
  @apply pb-5 ml-2 first:ml-0 xl:ml-0 xl:pr-5 xl:relative xl:overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root a {
    @apply inline-flex items-center px-7 py-3 text-xl bg-blue-500 text-white rounded-full max-w-full relative z-2 xl:text-sm xl:px-5 xl:hover:bg-secondary-700 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > a {
    @apply before:absolute before:left-full before:w-[120%] before:h-px before:bg-neutral-700 before:-z-1 {} }
  .AspNet-TreeView .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
    @apply hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root li {
    @apply pl-3 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li a {
      @apply bg-blue-500/90 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li a {
      @apply bg-blue-500/75 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li li a {
      @apply bg-blue-500/60 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li:last-child {
    @apply before:absolute before:w-px before:h-full before:left-0 before:top-[65%] before:-translate-y-1/2 before:bg-white before:z-1 before:pointer-events-none {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Leaf:last-child {
    @apply before:top-full {} }
  .AspNet-TreeView .AspNet-TreeView-Root:last-child > a {
    @apply before:hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root > ul, .AspNet-TreeView .AspNet-TreeView-Leaf > ul, .AspNet-TreeView .AspNet-TreeView-Parent > ul {
  @apply ml-5 overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li {
    @apply mt-2 relative z-1 {}    @apply after:absolute after:w-px after:h-[200%] after:left-0 after:top-1/2 after:-translate-y-full after:bg-neutral-700 after:pointer-events-none {} }
    .AspNet-TreeView .AspNet-TreeView-Root > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li > a {
      @apply relative {}      @apply before:absolute before:w-3 before:-z-1 before:h-px before:bg-neutral-700 before:top-1/2 before:-translate-y-1/2 before:right-full before:pointer-events-none {} }

.footer-info {
  @apply text-white body-16 font-normal {} }
  .footer-info h3 {
    @apply sub-header-24 font-bold mb-6 {} }
  .footer-info h4 {
    @apply sub-header-20 font-bold mb-3 {} }
  .footer-info * + h4 {
    @apply mt-10 {} }
  .footer-info a {
    @apply text-primary-2 body-14 font-normal underline {} }
    .footer-info a strong {
      @apply text-white no-underline {} }
  .footer-info p + p {
    @apply rem:mt-[6px] {} }
  .footer-info figure {
    @apply flex items-center rounded-1 bg-secondary-800 xl:rem:max-w-[253px] {} }
    .footer-info figure img {
      @apply size-20 rounded-1 object-cover {} }
  .footer-info figcaption {
    @apply flex-1 py-2 px-3 body-14 font-normal space-y-1 {} }
    .footer-info figcaption h4 {
      @apply body-16 font-bold mb-0 {} }

.footer-title {
  @apply sub-header-20 font-bold text-white {} }

.footer-menu {
  @apply space-y-5 {} }
  .footer-menu a {
    @apply body-16 font-normal text-white hover-underline {} }

.footer-contact {
  @apply body-14 font-normal text-white {} }
  .footer-contact .footer-title {
    @apply mb-3 {} }
  .footer-contact .social-list {
    @apply mt-4 {} }
  .footer-contact p a {
    @apply hover-underline {} }

.footer-top {
  @apply xl:pt-20 {} }

.footer-bot {
  @apply body-14 font-normal text-white/70 text-center rem:space-y-[10px] {} }
  .footer-bot ul {
    @apply flex-center flex-wrap gap-3 xl:gap-5 {} }

footer {
  background-position: left bottom !important; }

@screen xl {
  footer .row {
    @apply rem:-mx-[calc(73px/2)] {} }
    footer .row > * {
      @apply rem:px-[calc(73px/2)] {} }
  footer .col-cus-1 {
    @apply w-[calc(433/1473*100%)] {} }
  footer .col-cus-2 {
    @apply w-[calc(281/1473*100%)] {} }
  footer .col-cus-3 {
    @apply w-[calc(441/1473*100%)] {} }
  footer .col-cus-4 {
    @apply w-[calc(318/1473*100%)] {} } }
