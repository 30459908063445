.swiper-pagination
	.swiper-pagination-bullet
		@apply opacity-100 w-3 h-3 rounded-full bg-secondary-700/50
		&.swiper-pagination-bullet-active
			@apply bg-secondary-700
	&.is-white
		.swiper-pagination-bullet
			@apply bg-white/50
			&.swiper-pagination-bullet-active
				@apply bg-white

.swiper-button
	@apply flex-center gap-5 mt-5 xl:mt-0
	> *
		@apply size-12 text-xl text-white rounded-1 bg-black/10 flex-center xl:size-15 xl:text-2xl
		&.swiper-button-disabled
			@apply opacity-40
		&.swiper-button-lock
			@apply opacity-0 pointer-events-none
	&.is-white
		> *
			@apply text-white bg-white/20 border-white hover:text-neutral-200
	&.is-abs
		> *
			@apply absolute top-1/2 -translate-y-1/2 z-1
		.button-prev
			@apply -left-12 xl:-left-20
		.button-next
			@apply -right-12 xl:-right-20
		&.is-top-20
			> *
				@apply top-[20%]
		&.is-top-30
			> *
				@apply top-[30%]
		&.is-top-40
			> *
				@apply top-[40%]

.swiper-relative
	@apply lg:px-10 xl:px-0
	&.is-page
		@apply pb-10 lg:pb-0
	.swiper-scrollbar
		@apply h-2 bg-primary-1 rounded-full left-0 w-full
		.swiper-scrollbar-drag
			@apply bg-primary-2 rounded-full

.one-autoplay
	.swiper-wrapper
		transition-timing-function: linear
