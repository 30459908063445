.home-1-item
	@apply relative py-6
	.icon
		@apply size-15 flex-center mx-auto
	.number
		@apply mt-3 header-40 font-bold text-primary-1 text-center flex-center
		@apply leading-[1.2]
	.title
		@apply mt-2 body-16 font-normal text-neutral-950 text-center rem:max-w-[184px] mx-auto
	.line
		@apply flex-center gap-1 absolute top-1/2 -translate-y-1/2 -right-20 hidden
		span
			@apply w-6 h-2 bg-[#8DC63F] opacity-50
			&:nth-child(2)
				@apply bg-primary-1 opacity-100 rounded-tl-[2px] rounded-br-[2px] w-2
	@screen xl
		.line
			@apply flex
		&:nth-child(4n)
			.line
				@apply hidden

.home-1-list
	@apply grid grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-4 xl:gap-x-23 xl:gap-y-8

.home-1-section
	@screen xl
		background-position: center bottom !important
		@apply rem:pt-[70px]

.home-2-section
	.swiper
		@apply xl:overflow-visible
	.swiper-relative
		@apply pb-5 xl:pb-10

.home-4-section
	.site-desc
		@apply text-white
	.image
		@apply rem:max-w-[1169px] mx-auto
		svg
			@apply w-full h-full object-contain

.home-5-item
	@apply flex flex-col md:flex-row md:even:flex-row-reverse
	.image
		@apply aspect-[700/400]
	.caption
		@apply px-5 py-10 lg:p-15 xl:p-20 md:flex md:flex-col md:justify-center
	.news-date
		@apply text-white
	.title
		@apply sub-header-24 font-bold text-white line-clamp-2
	.desc
		@apply body-16 font-normal text-white line-clamp-2
	.button
		a
			@apply bg-white
			@apply hover:bg-[#5C8C24] hover:text-white hover:border-[#5C8C24]
	&:nth-child(1)
		.news-date
			@apply text-neutral-500
		.title
			@apply text-primary-1
		.desc
			@apply text-neutral-900
		.button
			a
				@apply bg-primary-2 border-none text-white
				@apply hover:bg-[#5C8C24]
	@screen md
		> *
			@apply w-1/2

.home-5-list
	@apply rounded-6 overflow-hidden

.home-5-section
	.tabslet-tabs
		@apply flex justify-center rem:max-w-[480px] mx-auto
		a
			@apply flex-center text-center border border-primary-1 py-2 px-3 body-18 font-bold text-primary-1 bg-white tsn min-h-11 h-full xl:px-5
		li
			@apply basis-full
			&.active
				a
					@apply bg-primary-2 border-primary-2 text-white
			&:first-child
				a
					@apply rounded-l-full
			&:last-child
				a
					@apply rounded-r-full

.home-7-section
	.image
		@apply rem:w-[740px] h-full mt-5 max-w-full
		.ModuleContent
			@apply h-full
		@screen xl
			@apply absolute top-0 left-0 mt-0
	.block-content
		@apply xl:rem:max-w-[840px] xl:ml-auto xl:mr-0
	@media only screen and (max-width: 575.98px)
		.site-title
			@apply text-2xl
