.contact-list
	@apply grid grid-cols-2 gap-5 sm:grid-cols-3 lg:grid-cols-3 xl:gap-10

.contact-item
	@apply bg-neutral-50 rounded-3 p-5 body-16 font-normal text-neutral-950 text-center xl:p-10 xl:rounded-6
	.icon
		@apply size-12 bg-primary-1 rounded-2 flex-center mx-auto text-xl text-white mb-3
	.title
		@apply sub-header-20 font-bold text-neutral-950 mb-5
	a
		@apply hover:text-primary-1
		word-wrap: break-word

.contact-maps
	.iframe-scale
		@apply pt-full rounded-3 overflow-hidden xl:rounded-6

.contact-form
	@apply bg-neutral-50 rounded-3 p-5 xl:p-10 xl:rounded-6 xl:h-full
