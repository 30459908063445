.button
	@apply flex flex-wrap gap-5

.link

.btn-solid
	@apply flex-center gap-3 body-16 font-medium text-white bg-primary-1 rounded-full px-4 py-2 min-h-11
	@apply hover:text-white xl:px-6
	i
		@apply text-xl
	.icon
		@apply rem:w-[18px] overflow-hidden flex items-center
		> *
			@apply -translate-x-full w-full flex-center tsn
	&:hover
		.icon
			> *
				@apply translate-x-0
	&.is-red
		@apply bg-[#E20000] hover:bg-[#C60202]
	&.is-light-green
		@apply bg-primary-2 hover:bg-[#5C8C24]

.btn-lined
	@apply flex-center gap-3 body-16 font-medium text-primary-1 border border-current rounded-full px-4 py-2 min-h-11
	@apply hover:text-white hover:bg-primary-1 xl:px-6
