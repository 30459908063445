.footer-info
	@apply text-white body-16 font-normal
	h3
		@apply sub-header-24 font-bold mb-6
	h4
		@apply sub-header-20 font-bold mb-3
	* + h4
		@apply mt-10
	a
		@apply text-primary-2 body-14 font-normal underline
		strong
			@apply text-white no-underline
	p
		& + p
			@apply rem:mt-[6px]
	figure
		@apply flex items-center rounded-1 bg-secondary-800 xl:rem:max-w-[253px]
		img
			@apply size-20 rounded-1 object-cover
	figcaption
		@apply flex-1 py-2 px-3 body-14 font-normal space-y-1
		h4
			@apply body-16 font-bold mb-0

.footer-title
	@apply sub-header-20 font-bold text-white

.footer-menu
	@apply space-y-5
	a
		@apply body-16 font-normal text-white hover-underline

.footer-contact
	@apply body-14 font-normal text-white
	.footer-title
		@apply mb-3
	.social-list
		@apply mt-4
	p
		a
			@apply hover-underline

.footer-top
	@apply xl:pt-20

.footer-bot
	@apply body-14 font-normal text-white/70 text-center rem:space-y-[10px]
	ul
		@apply flex-center flex-wrap gap-3 xl:gap-5

footer
	background-position: left bottom !important
	@screen xl
		.row
			@apply rem:-mx-[calc(73px/2)]
			> *
				@apply rem:px-[calc(73px/2)]
		.col-cus-1
			@apply w-[calc(433/1473*100%)]
		.col-cus-2
			@apply w-[calc(281/1473*100%)]
		.col-cus-3
			@apply w-[calc(441/1473*100%)]
		.col-cus-4
			@apply w-[calc(318/1473*100%)]
