.header-64
	@apply text-[32px] leading-none xl:text-64

.header-52
	@apply text-[30px] leading-1.125 xl:rem:text-[52px]

.header-48
	@apply text-[30px] leading-snug xl:text-5xl

.header-40
	@apply text-[28px] leading-snug xl:text-40

.section-header-32
	@apply text-[24px] leading-tight xl:text-32

.sub-header-24
	@apply text-[20px] leading-snug xl:text-2xl

.sub-header-20
	@apply text-[18px] leading-snug xl:text-xl

.body-18
	@apply text-[16px] leading-normal xl:text-lg

.body-16
	@apply text-[16px] leading-tight xl:text-base

.body-14
	@apply text-[14px] leading-tight xl:text-sm

.label-12
	@apply text-[12px] leading-snug xl:text-sm

.site-title
	@apply header-40 font-bold

.site-sub-title
	@apply section-header-32 font-bold

.site-desc
	@apply body-16 font-normal text-neutral-950 space-y-4 xl:space-y-6

.full-content
	@apply prose max-w-none
	@apply body-16 font-normal text-neutral-950
	> *
		@apply first:mt-0 last:mb-0
	p
		img
			@apply m-0
	ul
		@apply list-disc pl-4
	ol
		@apply list-decimal pl-4
