// button toggle menu
#buttonMenu
	@apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none m-0 p-0 bg-none
	.line
		@apply absolute block h-[2px] w-full bg-primary-1 rounded-full opacity-100 left-0 rotate-0 transition-all
		&:nth-child(1)
			@apply top-[4px]
		&:nth-child(2)
			@apply top-1/2 -translate-y-1/2
		&:nth-child(3)
			@apply bottom-[4px]
	#pulseMe
		@apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1
	.bar
		@apply bg-primary-1 rounded-1 absolute shadow-bar
		&.left
			@apply w-px animate-left-bar
		&.top
			@apply h-px animate-top-bar
		&.right
			@apply w-px animate-right-bar
		&.bottom
			@apply h-px animate-bottom-bar
	&.open, &:hover
		.line
			&:nth-child(2)
				@apply w-[15px]
		.bar
			@apply hidden
	@screen xl
		display: none !important

// alert
.alert
	@apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm
	& button
		@apply hidden
	&.alert-danger
		color: #721c24
		background-color: #f8d7da
		border-color: #f5c6cb
	&.alert-success
		color: #155724
		background-color: #d4edda
		border-color: #c3e6cb

.backdrop
	@apply fixed z-50 size-full top-0 left-0 bg-black/75 backdrop-blur hidden

.cta-fixed
	@apply fixed z-50 bottom-24 right-3 md:bottom-5 xl:bottom-15 xl:right-10
	ul
		@apply flex flex-col gap-2 xl:gap-3
	li
		animation: ctaPhoneRing 1s infinite ease-in-out
		@apply size-12 rounded-full flex-center overflow-hidden bg-primary-2 text-white text-xl transition-all shadow-drop-shadow-light hover:bg-primary-1 xl:size-15 xl:text-2xl
		a
			@apply size-full flex-center p-2
		&.back-to-top
			@apply bg-neutral-500 opacity-0 pointer-events-none hover:bg-neutral-700
			&.active
				@apply opacity-100 pointer-events-auto
	.ActionMenu
		left: auto !important
		right: 0 !important

.social-list
	ul
		@apply flex flex-wrap rem:gap-[10px]
	a
		@apply size-14 rounded-1 flex-center text-white text-2xl bg-secondary-800 tsn hover:bg-secondary-950
