+form-text
	@apply bg-white border border-neutral-200 pl-5 pr-10 py-2 rounded-full text-base font-normal leading-normal text-neutral-500 w-full bg-no-repeat h-11 m-0
	@apply focus:outline-none focus:ring-2 focus:ring-secondary-700 focus:ring-offset-0

+form-input
	@apply placeholder:text-neutral-500
	// @apply placeholder-shown:bg-white
	@apply disabled:bg-neutral-500/50 disabled:border-neutral-500/75 disabled:text-neutral-900
	@apply read-only:bg-neutral-100 read-only:border-neutral-200 read-only:text-neutral-500

select
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%23A6BD39' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 8l4 4 4-4'/></svg>")
	@apply bg-right appearance-none

select[multiple]
	@apply min-h-[calc(48/1920*100rem)] py-3 h-auto bg-none

textarea
	@apply h-20 py-3 rounded-6 xl:py-5 xl:h-30
	@apply placeholder:text-neutral-500

input[type='file']
	@apply hidden
	~ label
		@apply relative w-max bg-neutral-600 border-none h-12 px-6 py-3 text-base text-primary-1 font-normal flex-center gap-3 cursor-pointer m-0 transition-all duration-200 ease-linear hover:bg-neutral-700

input[type='checkbox']
	@apply hidden
	~ label
		@apply relative pl-5
		&::before
			@apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal
	&:checked
		~ label
			&::before
				@apply content-['\f14a']

input[type='radio']
	@apply hidden
	~ label
		@apply relative pl-5
		&::before
			@apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal
	&:checked
		~ label
			&::before
				@apply content-['\f192']

.form-group
	@apply relative
	> label
		@apply relative block text-base font-normal leading-normal text-neutral-800
	[class*='fa-ex']
		@apply text-[12px] text-red-700 italic block mt-1 font-sans font-normal

// .qradio

// .qcheckbox

// .qselect

// .qnote

// button submit
.frm-btnwrap
	@apply mt-5 flex-center
	label.label, .frm-btn-reset
		@apply hidden
	input[type='submit'], button[type='submit']
		@apply h-11 rounded-full bg-primary-2 px-4 border-none text-base font-medium text-white text-center tsn xl:px-6
		@apply hover:bg-primary-1

// captcha
.frm-captcha
	@apply flex flex-wrap mt-0 lg:flex-row-reverse lg:justify-end
	> div
		@apply mt-5
	label
		@apply hidden
	.frm-captcha-input
		@apply w-full lg:w-auto
		input
			@apply w-full xl:max-w-[calc(260/1920*100rem)]
	.RadCaptcha
		@apply flex flex-col-reverse
		@apply w-max
		> div
			@apply w-full
			> div
				@apply flex items-center
		> span
			@apply text-[12px] text-secondary-700 italic block mt-1 font-sans font-normal
	.rcRefreshImage
		font-size: 0
		@apply before:font-awesome before:content-['\f2ea'] before:text-2xl before:text-blue-500 before:mx-5 xl:before:text-[calc(22/1920*100rem)] xl:before:ml-[calc(25.5/1920*100rem)] xl:before:mr-[calc(19.5/1920*100rem)]

.RadUpload
	@apply max-w-full
	input[type="file"]
		@apply block
	.ruInputs
		.ruRemove
			@apply text-0 w-auto
		li
			&:not(:last-child)
				@apply absolute top-0 left-0 flex items-center m-0 z-2 w-full px-5 bg-white h-11 rounded-full border border-neutral-200
			&:last-child
				@apply mb-0 opacity-0
				.ruFileWrap
					@apply block w-full h-11 rounded-full border border-neutral-200 bg-neutral-100 text-neutral-500 flex-center
					input
						@apply w-full h-full bg-transparent border-none text-neutral-500 text-left px-5

.qupload
	label
		@apply flex items-center cursor-pointer w-full h-11 rounded-full border border-neutral-200 bg-neutral-100 text-neutral-500 flex-center px-5
	.RadUpload
		@apply relative z-1 -mt-11

.apply-frm
	@apply p-5
	.form-group
		@apply mb-8 last:mb-0
		label
			@apply text-base w-full text-main font-normal mb-1
			span
				@apply text-secondary-700 text-sm
		input:not([type="button"]), textarea
			@apply border-neutral-700
	.frm-btn
		@apply w-full flex justify-end
	@screen lg
		@apply py-8 px-10
		.form-group
			@apply flex gap-8
			label
				@apply w-[30%] mb-0 text-base
			input:not([type="button"])
				@apply h-10 text-base
			textarea
				@apply h-25 text-base


.subscribefrm
	.fa
		@apply font-sans text-xs text-red-700 font-normal italic block mt-1
		&::before
			@apply font-awesome content-['\f071'] not-italic mr-1
	.subscribebutton
		@apply h-11 bg-primary-2 rounded-full flex-center gap-3 text-center body-16 font-medium text-white px-6 mt-3 tsn hover:bg-[#5C8C24]
		&::after
			@apply font-awesome content-['\f061'] font-light text-xl not-italic
