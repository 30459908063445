.career-desc
	@apply p-5 border border-primary-2 rounded-2 xl:p-10 xl:rounded-4
	.desc
		@apply body-16 font-normal text-neutral-950 rem:mt-[15px]
	ul
		@apply list-disc pl-4 rem:space-y-[15px]
		li
			@apply marker:text-neutral-300 pl-1

.career-content-item
	@apply pt-6 border-t border-neutral-200 body-16 font-normal text-neutral-950 first:border-none first:pt-0 last:pb-0 rem:space-y-[15px]
	h2
		@apply sub-header-20 font-bold text-neutral-950
	ul
		@apply list-disc pl-4 rem:space-y-[15px]
		li
			@apply marker:text-neutral-300 pl-1
	table
		@apply w-full border border-neutral-100
		tr
			@apply odd:bg-neutral-50
	td
		@apply body-16 font-normal text-black p-2 align-top xl:px-5 xl:rem:py-[10px]
		@apply first:font-bold last:pl-3 xl:first:w-[22%]

.career-content-list
	@apply space-y-6

.box-career-detail-button
	@apply flex flex-col gap-6 bg-neutral-50 rounded-4 p-5 xl:p-8
	a
		@apply w-full justify-between

.jobs-item
	@apply relative py-4 space-y-2 body-16 font-normal text-neutral-950 border-t border-neutral-100 first:border-none
	.title
		@apply sub-header-20 font-bold text-neutral-950 tsn
	.news-date
		@apply text-primary-1
	&:hover
		.title
			@apply text-primary-1
