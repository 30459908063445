.video-container
	@apply cursor-pointer
	.play-icon
		@apply absolute-center rounded-full bg-primary-2/[0.5] text-white flex items-center justify-center rem:w-[80px] rem:h-[80px] z-2 rem:text-[40px]
.home-banner
	.image
		@apply aspect-[1280/2580] md:aspect-[1920/860]
		.mb
			@apply md:hidden
			~ img
				@apply hidden md:block
	.container
		@apply absolute-center w-full z-1 pointer-events-none
		> * > *
			@apply pointer-events-auto
	.swiper-slide
		.container
			@apply top-[22%] md:top-1/2
	.caption
		@apply rem:max-w-[620px] p-5 bg-white/20 border-l-4 border-primary-2 xl:p-10
	.banner-title
		background: -webkit-linear-gradient(-90deg, #056839 0%, #5EA53D 60%, #8FC63F 100%)
		-webkit-background-clip: text
		-webkit-text-stroke: 6px transparent
		@apply rem:text-[34px] font-black text-white uppercase bg-clip-text xl:rem:text-[52px]
	.swiper-button
		@apply w-full justify-between hidden xl:flex
		> *
			@apply bg-black/40
		.button-prev
			@apply -translate-x-25
		.button-next
			@apply translate-x-25
	.swiper-pagination
		@apply bottom-20
		.swiper-pagination-bullet
			@apply w-5 h-1 rounded-full bg-white opacity-50 xl:w-10
		.swiper-pagination-bullet-active
			@apply bg-white opacity-100 xl:w-20
