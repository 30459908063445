.global-breadcrumb
	@apply bg-neutral-50
	.breadcrumb
		@apply flex flex-wrap items-center py-3 gap-x-3 gap-y-2
		a
			@apply block body-14 font-bold text-primary-1
			// &.active
			// 	@apply text-secondary-700
		li
			@apply flex items-center last:after:hidden
			&::after
				content: ''
				@apply ml-3 w-px h-6 bg-primary-1
			&:first-child
				a
					@apply text-0
					span
						@apply hidden
					&::before
						@apply font-awesome content-['\f015'] text-primary-1 text-base font-bold leading-none flex-center

	// .rank-math-breadcrumb
	// 	@apply py-2 xl:rem:py-[10px]
	// 	p
	// 		@apply flex flex-wrap items-center gap-2 xl:gap-4
	// 		> *
	// 			@apply body-16 font-normal text-neutral-400
	// 			&:first-child
	// 				@apply text-0
	// 				&::before
	// 					@apply font-awesome content-['\e487'] text-neutral-400 text-base font-thin leading-none flex-center
	// 		a
	// 			@apply hover:text-primary-1
	// 	.separator
	// 		@apply text-0
	// 		&::after
	// 			content: '|'
	// 			@apply text-base font-normal text-neutral-400 leading-none
