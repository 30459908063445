.banner-child
	.image
		@apply aspect-[1920/940] md:aspect-[1920/540]
	.container
		@apply absolute-center w-full z-1
	.caption
		@apply rem:max-w-[620px] p-5 bg-primary-1/70 border-l-4 border-primary-2 xl:p-10
	.banner-title
		background: -webkit-linear-gradient(-90deg, #056839 0%, #5EA53D 60%, #8FC63F 100%);
		-webkit-background-clip: text
		-webkit-text-stroke: 3px transparent
		@apply rem:text-[30px] font-black text-white uppercase bg-clip-text xl:rem:text-[52px]

.product-detail-page, .recruitment-detail-page, .news-detail-page
	.banner-child
		@apply hidden
