.about-1-section
	@apply xl:pb-26
	.block-content
		@apply flex flex-col gap-10 lg:flex-row xl:gap-15
	.image-content
		@apply aspect-[320/470] rem:w-[320px] rounded-5 mx-auto
	.image
		@apply aspect-[457/608] rounded-6
	.site-desc
		@apply lg:flex-1
	.bg
		@apply hidden absolute bottom-0 xl:block
	.about-1-bg
		@apply left-0 rem:w-[820px] aspect-[820/222]
	.about-2-bg
		@apply right-11 rem:w-[621px] aspect-[621/186]
	@screen xl
		.row
			@apply -mx-3
			> *
				@apply px-3
		.col-cus-1
			@apply w-[calc(944/1424*100%)]
		.col-cus-2
			@apply w-[calc(480/1424*100%)]

.about-2-item
	@apply bg-white rounded-5 p-3 flex flex-col gap-5 tsn md:flex-row md:items-center xl:gap-10 xl:p-5
	*
		@apply tsn
	.image
		@apply aspect-[280/186] rounded-5 md:rem:w-[280px]
	.caption
		@apply flex-1
	.year-title
		@apply section-header-32 font-bold text-primary-1
	.desc
		@apply body-16 font-normal text-neutral-950

.about-2-thumbs
	@apply relative
	&::before
		content: ''
		@apply absolute left-0 bottom-2 w-full h-px bg-white/30
	.year-dot
		@apply sub-header-24 font-bold text-white text-center h-10 mb-6 flex-center cursor-pointer
		&::before
			content: ''
			@apply absolute left-1/2 -translate-x-1/2 bottom-1 size-2 bg-white rounded-full z-1
		&::after
			content: ''
			@apply absolute left-1/2 -translate-x-1/2 bottom-0 size-4 bg-white rounded-full opacity-0
	.swiper-slide-thumb-active
		.year-dot
			@apply text-primary-2 text-32
			&::before
				@apply rem:size-[10px] rem:bottom-[3px] bg-primary-2
			&::after
				@apply opacity-100

.about-2-top
	@apply rem:max-w-[760px] mx-auto

.about-2-section
	@apply overflow-hidden xl:pt-25 xl:pb-27
	.swiper-button
		@screen xl
			> *
				@apply absolute top-1/2 -translate-y-1/2 z-1
			.button-prev
				@apply -left-25
			.button-next
				@apply -right-25
	@screen xl
		.about-2-top
			@apply overflow-visible
			.about-2-item
				@apply bg-transparent px-15 rem:h-[226px]
				.image
					@apply hidden
				.year-title
					@apply header-48 text-white text-right
				.desc
					@apply hidden
			.swiper-slide-active
				.about-2-item
					@apply bg-white p-5
					.image
						@apply block
					.year-title
						@apply text-left section-header-32 text-primary-1
					.desc
						@apply block
				& + .swiper-slide
					.about-2-item
						.year-title
							@apply text-left


.about-3-item
	@apply py-6 border-b border-neutral-200 last:border-none first:pt-0 last:pb-0
	.icon
		@apply size-13
	.title
		@apply section-header-32 font-bold text-primary-1
	.desc
		@apply body-16 font-normal text-neutral-950

.about-3-section
	.image
		@apply aspect-[680/606] rounded-6 xl:rem:max-w-[680px]
	.about-3-bg
		@apply hidden h-full absolute top-0 left-0 rem:w-[700px] -z-1 lg:block
	.block-content
		@apply xl:pl-10

.about-4-item
	@apply relative rounded-4 overflow-hidden
	.image
		@apply aspect-[338/450]
	.caption
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%)
		@apply absolute bottom-0 left-0 w-full p-5 xl:py-6
	.title
		@apply sub-header-20 font-bold text-white text-center

.about-4-list
	@apply grid grid-cols-1 rem:gap-[15px] sm:grid-cols-2 lg:grid-cols-4

.factory-item
	@apply px-3 rem:h-[127px] flex-center bg-white border border-neutral-200 rounded-2 grayscale tsn opacity-30

.factory-slider
	.swiper-button
		@apply mb-10 xl:mb-0
		@screen xl
			> *
				@apply absolute top-1/2 -translate-y-1/2 z-1
			.button-prev
				@apply -left-25
			.button-next
				@apply -right-25
	.swiper-wrapper
		@apply items-center
	.swiper-slide
		@apply rem:w-[250px] py-5 rem:h-[190px] flex-center xl:py-10 xl:rem:h-[230px]
		&.swiper-slide-active
			.factory-item
				box-shadow: 4px 4px 20px 12px rgba(0, 0, 0, 0.08)
				@apply grayscale-0 opacity-100 rounded-3 h-full
			@screen md
				@apply rem:w-[302px]

.album-slider
	.swiper
		@apply xl:overflow-visible
	.image
		@apply aspect-[1160/610] rounded-6 relative
		&::before
			content: ''
			backdrop-filter: blur(6px)
			@apply absolute top-0 left-0 w-full h-full bg-white/60 z-1 pointer-events-none
	.caption
		@apply tsn mt-5
	.title
		@apply sub-header-20 font-bold text-primary-1 text-center
	.swiper-button
		@screen xl
			> *
				@apply absolute top-1/2 -translate-y-1/2 z-1
			.button-prev
				@apply -left-30
			.button-next
				@apply -right-30
	.swiper-slide
		&.swiper-slide-active
			.image
				&::before
					@apply hidden

.about-6-item
	@apply pb-3 pt-5 border-b-2 border-neutral-100
	.title
		@apply sub-header-20 font-normal text-neutral-950
	.number
		@apply mt-3 rem:text-[30px] font-black text-primary-1 flex items-center xl:rem:text-[44px] xl:leading-[calc(60/44)]
	.count-up
		@apply mr-1

.about-6-list
	@apply grid grid-cols-2 gap-5 xl:gap-y-6 xl:gap-x-16
	.about-6-item
		&:nth-child(1), &:nth-child(2)
			@apply pt-0

.about-6-section
	.image
		@apply aspect-[560/696] rounded-5
	.block-content
		@apply xl:pl-10

.customers-item
	@apply rem:h-[98px] rounded-3 border border-neutral-200 p-3 bg-white
	img
		@apply tsn
	&:hover
		img
			@apply scale-110

.about-7-section
	.swiper-button
		@screen xl
			> *
				@apply absolute top-1/2 -translate-y-1/2 z-1
			.button-prev
				@apply -left-25
			.button-next
				@apply -right-25

.partner-item
	@apply h-24 flex-center

.partner-slider
	.swiper-slide
		@apply rem:w-[192px]
