.product-top-slider
	.image
		@apply aspect-[660/495] rounded-3 xl:rounded-6

.product-thumbs-slider
	.image
		@apply aspect-[100/75] rounded-2
	.swiper-slide
		@apply opacity-70
		&::before
			content: ''
			@apply absolute inset-0 border border-primary-1 opacity-0 rounded-2 z-1 pointer-events-none
		&.swiper-slide-thumb-active
			@apply opacity-100
			&::before
				@apply opacity-100

.product-table
	@apply rem:pr-[18px] rem:max-h-[460px] overflow-y-auto
	&::-webkit-scrollbar
		width: 2px
	&::-webkit-scrollbar-track
		background-color: theme('colors.neutral.100')
	&::-webkit-scrollbar-thumb
		background-color: theme('colors.secondary.900')
	&::-webkit-scrollbar-thumb:hover
		background-color: theme('colors.secondary.800')
	table
		@apply w-full
	tr
		@apply even:bg-neutral-50
	td
		@apply body-16 font-normal text-black align-top px-3 py-2 xl:rem:py-[10px]
		@apply first:font-bold first:w-[38%] xl:first:px-5 xl:last:pl-0

.product-detail-section
	.swiper-button
		@apply absolute bottom-3 left-3 z-1 mt-0 xl:left-6 xl:bottom-6
	.product-slider
		@apply lg:rem:max-w-[660px]
	.block-content
		@apply lg:rem:max-w-[660px] lg:ml-auto lg:mr-0

.product-detail-other-section
	.product-item
		@apply bg-white hover:bg-primary-1
	.swiper-button
		@screen xl
			> *
				@apply absolute top-1/2 -translate-y-1/2 z-1
			.button-prev
				@apply -left-25
			.button-next
				@apply -right-25

