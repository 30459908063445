.modal
	@apply bg-transparent bg-none p-0 w-full max-w-7xl hidden
	.modal-wrap
		@apply bg-white p-5 rounded-2 shadow

.modal-head

.modal-body

.modal-product
	@apply rem:max-w-[760px]
	.modal-wrap
		@apply xl:rounded-6 xl:py-15 xl:px-10

.modal-apply-now
	@apply rem:max-w-[846px]
	.modal-wrap
		@apply xl:px-10 xl:py-15 xl:rounded-6
	.row
		@apply rem:-mx-[10px]
		> *
			@apply rem:px-[10px]
