header
	@apply fixed top-0 left-0 right-0 z-998 w-full bg-white shadow-drop-shadow-light xl:h-25
	.header-wrap
		@apply flex justify-between items-center gap-5 py-3 xl:py-5 xl:rem:gap-[54px]
	.header-left
		@apply hidden xl:block
	.header-right, .header-left
		@apply flex-1
	.header-right, .button-group
		@apply flex items-center justify-end
	.header-right
		@apply gap-5 xl:gap-25
	.logo
		@apply h-15
		p, a, .ModuleContent
			@apply h-full flex-center
		img
			@apply w-auto h-full object-contain
	.button-group
		@apply gap-6
	.main-menu
		@apply hidden items-center gap-5 xl:gap-23 xl:flex
		a
			@apply sub-header-20 font-bold text-neutral-950 py-1 hover-underline hover:text-primary-1
		li
			&.active
				a
					@apply text-primary-1 hover-underline-active
	.header-right
		.main-menu
			@apply justify-end xl:gap-25
	.button-search
		@apply text-2xl text-neutral-950 tsn hover:text-primary-1 cursor-pointer

.mobile-wrap
	@apply fixed top-0 w-screen h-screen bg-white shadow z-100 pt-32 pl-4 pb-4 max-w-xs -left-full opacity-0 transition-all duration-500 ease-in-out pointer-events-none xl:hidden
	.navbar-nav-list
		@apply pr-4 flex flex-col gap-5
	.main-menu
		@apply flex flex-col gap-5
		a
			@apply sub-header-20 font-bold text-neutral-950 py-1 hover-underline hover:text-primary-1
		li
			&.active
				a
					@apply text-primary-1 hover-underline-active
	&.active
		@apply left-0 opacity-100 pointer-events-auto

.search-wrap
	@apply fixed top-32 left-0 right-0 w-full z-100 hidden px-10 xl:rem:top-[160px]

.wpml-ls
	&.wpml-ls-legacy-dropdown-click
		@apply w-full relative
		> ul
			> li
				@apply flex-center
				> a
					@apply flex-center sub-header-20 font-bold text-neutral-950 bg-none bg-transparent p-0 border-0
					&::after
						@apply font-awesome content-['\f0d7'] text-xs font-bold text-current ml-1 border-none flex static
				>.wpml-ls-sub-menu
					@apply border-none absolute top-full bg-white hidden p-5
					a
						@apply flex-center sub-header-20 font-bold text-neutral-950 bg-none bg-transparent p-0 border-0
				&:hover
					> .wpml-ls-sub-menu
						@apply block
