.link-overlay
	@apply absolute inset-0 z-2 size-full #{!important}

.product-item
	@apply relative flex flex-col gap-3 p-3 rounded-3 bg-neutral-50 tsn xl:p-5 xl:gap-5 xl:rounded-6
	@apply hover:bg-primary-1
	*
		@apply tsn
	.image
		@apply aspect-square rounded-3 bg-neutral-50 xl:rounded-6
	.title
		@apply sub-header-20 font-normal text-primary-1 text-center
	.link
		@apply flex-center gap-1 text-[12px] font-bold text-[#818998] tsn hover:text-primary-2
	.caption
		@apply flex flex-col justify-between flex-1
	&:hover
		box-shadow: 4px 4px 24px 0px rgba(102, 226, 41, 0.40)
		.title
			@apply text-white
		.link
			@apply text-white hover:text-primary-2

.product-list
	@apply grid grid-cols-2 gap-5 sm:grid-cols-3 lg:grid-cols-4 xl:gap-10

.box-category
	@apply rounded-3 xl:rounded-6 overflow-hidden shadow-drop-shadow-light
	.box-title
		@apply sub-header-24 font-bold text-white bg-primary-1 py-3 xl:rem:py-[14px] px-4 xl:rem:px-[30px]
	.box-body
		@apply px-4 py-2 xl:px-5
		a
			@apply body-16 font-normal text-black block py-3 tsn relative
			@apply hover:text-primary-2 hover:pl-7 hover:before:opacity-100
			&::before
				@apply font-awesome content-['\f178'] absolute left-0 top-1/2 -translate-y-1/2 text-current text-base font-normal opacity-0 tsn
		li
			@apply border-b border-neutral-200 last:border-none
			&.active
				a
					@apply text-primary-2 pl-7 before:opacity-100
