.news-date
	@apply body-14 font-normal text-neutral-500

.news-category
	@apply body-14 font-bold text-primary-2

.news-item
	@apply relative flex flex-col gap-5
	.image
		@apply aspect-square rounded-3 xl:rounded-6
	.caption
		@apply md:flex md:flex-col md:justify-center
	.news-date
		@apply mb-2
	.title
		@apply sub-header-20 font-bold text-primary-1 line-clamp-3
	.desc
		@apply mt-2 body-16 font-normal text-neutral-950 line-clamp-3
	.button
		a
			@apply relative z-[3]
	&.is-big
		@apply gap-0 rounded-3 overflow-hidden shadow-drop-shadow-light xl:rounded-6
		.image
			@apply rounded-none
		.caption
			@apply p-5
		@screen md
			@apply flex-row
			> *
				@apply w-1/2
		@screen lg
			.image
				@apply w-2/3 aspect-[700/400]
			.caption
				@apply flex-1 w-auto
		@screen xl
			.caption
				@apply px-10
			.title
				@apply sub-header-24
	&.is-hor
		@apply flex-row
		.image
			@apply w-1/3 rounded-3
		.caption
			@apply flex-1
		.title
			@apply body-16

.news-list
	@apply grid grid-cols-2 gap-5 sm:grid-cols-3 lg:grid-cols-4 xl:gap-10

.head-flex
	@apply flex flex-col gap-5 md:flex-row md:items-center md:justify-between

.site-nav
	.nav-list
		@apply flex flex-wrap items-center gap-5
		a
			@apply body-18 font-bold text-neutral-950 hover:text-primary-1 hover-underline
		li
			&.active
				a
					@apply text-primary-1
